import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import TransitionLink from 'gatsby-plugin-transition-link'
import React, { useRef } from 'react'
import Slick from "react-slick"
import "slick-carousel/slick/slick.css"
import Formularz from '../../components/formularz/formularz'
import Intro from '../../components/projekty/comp/projekt_intro'
import Inne from '../../components/projekty/inne'
import Ocena from '../../components/projekty/OcenaProjektu'
import Research from '../../components/projekty/rite/1'
import Strategia from '../../components/projekty/rite/2'
import Identyfikacja from '../../components/projekty/rite/3'
import WebDesign from '../../components/projekty/rite/4'
import Seo from '../../components/seo.js'
import Slider from '../../components/slider/slider'
import back from '../../images/ikony/goback.svg'
import oko from '../../images/ikony/oko.svg'
import strzalka from '../../images/ikony/strzalka.svg'
import okladka2 from '../../images/projekty/rite/okladka2@2x.jpg'
import okladka from '../../images/projekty/rite/okladka@2x.jpg'
import style from './projekt.module.css'





let title = "RITE"

export default function Betard() {
    const wrapper = useRef(null)

    const r1 = useRef(null)
    const r2 = useRef(null)
    const r3 = useRef(null)
    const r4 = useRef(null)
    const r5 = useRef(null)
    const r6 = useRef(null)
    const r7 = useRef(null)
    const r8 = useRef(null)

    const slider = useRef(null)

    useScrollPosition(
        ({ prevPos, currPos }) => {
        console.log(currPos.x)
        console.log(currPos.y)
        },
        [],
        [wrapper],
        false,
        300,
        )


        let settingss = {
            dots: false,
            adaptiveHeight: true,
            infinite: false,
            arrows: false,
            centerMode: false,
            speed: 300,
            slidesToShow: 3,
            variableWidth: true, 
          }   


    return (    
        <>
        <div style={{
            height: "calc(100vh - 60px)",
            overflow: "y-scroll",
        }}
        ref={wrapper}>
            <div id="projekt-bread" className="projekt-aktywny projekt-header">
                <div className="lista-item">
                    <TransitionLink style={{
                        left: '0',
                        top: '0',
                        position:'absolute',
                    }} to="/projekty">
                        <img className="projekt-header-cofnij" src={back} />
                    </TransitionLink>
                    <h3>{title}</h3>
                    <div className="projekt-zajawka">
                        <img src={oko} />
                        <span className="przycisk">2020</span>
                        <div className="projekt-zajawka-kategorie">
                        <span className="przycisk">badania i koncept</span>
                            <span className="przycisk">strategia wizualna</span>
                            <span className="przycisk">brand design</span>
                            <span className="przycisk">katalogi</span>
                            <span className="przycisk">akcydensy</span>
                            <span className="przycisk">web design</span>
                        </div>
                        
                        <p>Rite to nowoczesna kancelaria prawna, założona przez młody i dynamiczny zespół prawników z Wrocławia. </p>
                        <span className="przycisk">ZOBACZ PROJEKT</span>
                    </div>

                    { <div className="bread-dodatkowe slider-rozdzialy">
                    <div className="rozdzialy-maska"></div>
                    <Slick  ref={slider} className="rozdzial-slider" {...settingss}>
                    
                        <span onClick={
                            () => { r1.current.scrollIntoView({ behavior: 'smooth', block: 'start' }); slider.current.slickGoTo(0);}}
                            className="przycisk">01. RESEARCH I KONCEPCJA</span>
                        <span onClick={
                            () => { r2.current.scrollIntoView({ behavior: 'smooth', block: 'start' }); slider.current.slickGoTo(1);}}
                        
                        
                        className="przycisk">02. STRATEGIA MARKI</span>  
                        <span onClick={
                            () => { r3.current.scrollIntoView({ behavior: 'smooth', block: 'start' }); slider.current.slickGoTo(2);}} 
                       className="przycisk">03. IDENTYFIKACJA MARKI</span>  
                        <span onClick={
                            () => { r4.current.scrollIntoView({ behavior: 'smooth', block: 'start' }); slider.current.slickGoTo(3);}}
                        className="przycisk">04. WEB DESIGN</span>  
                       
                      
            
                        
                    </Slick>
                    <div className="rozdzialy-maska"></div>
                    </div>}

                </div>

            </div>


            <Seo title={title} description="Rite to nowoczesna kancelaria prawna, założona przez młody i dynamiczny zespół prawników z Wrocławia. Naszym celem było zaprojektowanie kancelarii innej niż  wszystkie, która spojrzy świeżym okiem na oczekiwania klientów i dopasuje swój wizerunek oraz ofertę  do ich oczekiwań." image={okladka} />
            <div className="podstrona-wrapper projekt-padding">

            <img style={{width: '100%'}} src={okladka} />

            <Intro tytul="Wprowadzenie do projektu:" tekst="Rite to nowoczesna kancelaria prawna, założona przez młody i dynamiczny zespół prawników z Wrocławia. Naszym celem było zaprojektowanie kancelarii innej niż  wszystkie, która spojrzy świeżym okiem na oczekiwania klientów i dopasuje swój wizerunek oraz ofertę  do ich oczekiwań."/>

            <img style={{width: '100%'}} src={okladka2} />








            <div className={style.architektura}>
                <h3>ARCHITEKTURA PROJEKTU</h3>
                <div>

               
                        <div onClick={() => r1.current.scrollIntoView({ behavior: 'smooth', block: 'start' })   } data-rozdzial={1}>
                            <span className="przycisk">
                                01. RESEARCH I KONCEPCJA
                            </span>
                            <img src={strzalka} />
                            <div className={style.architektura_acco}>
                                <img src={oko} />
                                <h6></h6>
                            </div>
                        </div>

                        <div onClick={() => r2.current.scrollIntoView({ behavior: 'smooth', block: 'start' })   }  data-rozdzial={2}>
                            <span className="przycisk">
                                02. STRATEGIA MARKI
                            </span>
                            <img src={strzalka} />
                            <div className={style.architektura_acco}>
                                <img src={oko} />
                                <h6></h6>
                            </div>
                        </div>

                        <div onClick={() => r3.current.scrollIntoView({ behavior: 'smooth', block: 'start' })   }  data-rozdzial={3}>
                            <span className="przycisk">
                                03. IDENTYFIKACJA MARKI
                            </span>
                            <img src={strzalka} />
                            <div className={style.architektura_acco}>
                                <img src={oko} />
                                <h6></h6>
                            </div>
                        </div>
                        
                        <div onClick={() => r4.current.scrollIntoView({ behavior: 'smooth', block: 'start' })   }   data-rozdzial={4}>
                            <span className="przycisk">
                                04. WEB DESIGN
                            </span>
                            <img src={strzalka} />
                            <div className={style.architektura_acco}>
                                <img src={oko} />
                                <h6></h6>
                            </div>
                        </div>


           
                </div>
            </div>
            </div>

            <div ref={r1}><Research  /></div>
            <div ref={r2}><Strategia /></div>
            <div ref={r3}><Identyfikacja  /></div>
            <div ref={r4}><WebDesign /></div>



            <div className="podstrona-wrapper">
            <Ocena tekst='"Postawiliśmy przed zespołem Odry duże wyzwanie i czujemy, że dostarczane rozwiązania są niezwykle świadome, jakościowe i nowoczesne. Proces redesignu marki przebiega bardzo sprawnie i kompleksowo a jego efektem jest odświeżenie i jeszcze większa profesjonalizacja wizerunku marki. Zaprojektowana strona internetowa jest zgodna z najnowszymi trendami, zarówno w obszarze technologicznym jak i w kontekście rozwiązań user experience oraz user inter- face. W ramach bieżącej współpracy czujemy wsparcie oraz ogromne zaangażowanie Odry, co jest dla nas niezwykle istotne."' firma="Brand Manager, Betard" imie="Karolina Dziechcińska" />

            <Slider  uslugi={true} />
            </div>

            <Inne omin="Maciej Janowski" />


            


            <Formularz />


            </div>
        </> 
    )
}