import Img from "gatsby-image";
import React from 'react';
import style from './slider_taby.module.css';




class SliderInfo extends React.Component {

    constructor(props) {
        super(props);
        this.ref1 = React.createRef();
        this.ref2 = React.createRef();
        this.ref3 = React.createRef();
        this.state = {
            activeId: this.ref1 
        }
          
    };

    handleClick(refName, event) {
        this.setState({ activeId: refName})
    };







    render() {

        return(
            <div class={style.wrapper}>

            <div ref={this.ref1}   
            className={ this.state.activeId === this.ref1 && style.active }
            onClick={ this.handleClick.bind(this, this.ref1) } 
            id="1"
            
            >
                <div class={style.tab_side}>
                    <h6>SEGMENTACJA</h6>
                </div>
                <div class={style.tresc}>
                <Img fixed={this.props.img1} />
                <div>
                    <h6>{this.props.tytul1}</h6>
                    <p>{this.props.tekst1}</p>
                    </div>
                </div>
            </div>

            <div ref={this.ref2}  
            className={ this.state.activeId === this.ref2 && style.active  }
            onClick={ this.handleClick.bind(this, this.ref2) } 
            id="2"
            >
                <div class={style.tab_side}>
                    <h6>TARGETOWANIE</h6>
                </div>
                <div class={style.tresc}>

                <Img fixed={this.props.img2} />
                <div>
                    <h6>{this.props.tytul2}</h6>
                    <p>{this.props.tekst2}</p>
                </div>    
                </div>
            </div>

            <div ref={this.ref3} 
            className={ this.state.activeId === this.ref3 && style.active  }
            onClick={ this.handleClick.bind(this, this.ref3) } 
            id="3"
            >
                <div class={style.tab_side}>
                    <h6>POZYCJONOWANIE</h6>
                </div>
                <div class={style.tresc}>
                <Img fixed={this.props.img3} />
                <div>
                    <h6>{this.props.tytul3}</h6>
                    <p>{this.props.tekst3}</p>
                    </div>    
                </div>
            </div>



            </div>
        )
    }

        
    

}


export default SliderInfo;