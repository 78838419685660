import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"
import style from './slider_info.module.css'

export default function SliderInfo(props)  {
    const settings = {
        dots: false,
        speed: 300,
        infinite: true,
        autoplay: true,
        variableWidth: true,
        arrows: false,
        
        responsive: [
          
            {
                breakpoint: 1025,
                settings: {
                
      
          
                }
              },
    
        ],
    
      };
    return (
        <div className={style.wrapper}>
            <h6>{props.tytul}</h6>
            <div className={style.slider_wrapper}>
            <Slider {...settings}>
            {props.informacje.map((e, i) => (
                
                <div
                key={e}
                custom={i}
                className={style.slajd}
                >
                    <span className="przycisk">{e.tytul}</span>
                    <p>{e.opis}</p>
                </div>
              ))}
               </Slider>
               </div>
           
        </div>
    )
}