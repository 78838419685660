import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from 'react'
import Rozdzial from '../comp/rozdzial'
import TytulPunkty from '../comp/tytul_punkty'





export default function Ilustracja() {
    const data = useStaticQuery(graphql`
            query {
               

                okladka: file(relativePath: { eq: "projekty/rite/4/okladka@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid(maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

               

                proces: file(relativePath: { eq: "projekty/rite/4/proces@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

           

            

            }
            `)

    


    
    return(
        <>

        <Rozdzial tytul="WEB DESIGN" invert={true} tekst="Istotnym elementem zadania było opracowanie niewielkiej ale nowoczesnej strony internetowej. Responsywność była oczywistością. Oczekiwanymi funkcjonalnościami była możliwość umówienia się na płatne konsultacje online oraz przejrzysta wizualizacja oferty i procesu. Nam zależało na zbudowaniu doświadczenia marki Rite, które nie będzie powielało rozwiązań stosowanych przez konkurencyjne kancelarie. Witryna marki musiała wynikać z przyjętych założeń wizerunkowych i jasno komunikować charakter marki. " nr="04." img={data.okladka.childImageSharp.fluid}>
        
        <TytulPunkty tekst="Strona marki buduje doświadczenie Rite, prezentuje ofertę  i pozwala na zaaranżowanie spotkania online z partnerem kancelarii" tekst1="Funkcjonalności strony dopasowane są do założeń i możliwości klienta. Głównym oczekiwaniem była dostępność i możliwość zaaranżowania spotkań online. Klient chciał ominąć funkcjonalności, które wymagają częstych aktualizacji i sprawiają, że witryna staje się balastem w codziennym funkcjonowaniu kancelarii." tekst2="Główne elementy wizualne strony www opierają się na przewodnim symbolu Rite, drodze prowadzącej do sukcesu, która w tym wypadku przyjęła formę interaktywnego modelu 3D."/>

        
    
      
        <div><Img fluid={data.proces.childImageSharp.fluid}></Img></div>


      
        

        
        
        </Rozdzial>
        
        </>
    )
}