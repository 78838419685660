import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from 'react'
import Info from '../comp/punkt_flex'
import Rozdzial from '../comp/rozdzial'
import SliderTaby from '../comp/slider_taby'
import TytulPunkty from '../comp/tytul_punkty'






export default function Strategia() {
    const data = useStaticQuery(graphql`
            query {
                okladka: file(relativePath: { eq: "projekty/rite/2/okladka@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                            ...GatsbyImageSharpFluid
                            }
                    }
                    }


                slider_1: file(relativePath: { eq: "projekty/rite/2/slider/1.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                childImageSharp {
                    fluid( quality: 75) {
                        ...GatsbyImageSharpFluid
                        }
                }
                }

                slider_2: file(relativePath: { eq: "projekty/rite/2/slider/2.jpg"},
                sourceInstanceName: {
                    eq: "images"
                  }) {
                childImageSharp {
                    fluid( quality: 75) {
                        ...GatsbyImageSharpFluid
                        }
                }
                }

                slider_3: file(relativePath: { eq: "projekty/rite/2/slider/3.jpg"},
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( quality: 75) {
                            ...GatsbyImageSharpFluid
                            }
                }
                }

            

                

                vp: file(relativePath: { eq: "projekty/rite/2/final.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

               

            }
            `)

    




    
    
    return(
        <>

        <Rozdzial tytul="STRATEGIA MARKI" invert={true} tekst="Zależało nam na znalezieniu takiej perspektywy przedstawienia usług naszych klientów, która nie powieli utartych schematów, odniesie się do realnych potrzeb i marzeń odbiorców, jednocześnie oddając wizerunek marki, silnie nacechowany osobowością jej właścicieli. Dynamicznych i ambitnych prawników z Wrocławia. Dzięki dogłębnej analizie rynku i stworzeniu strategii wizerunkowej, możemy planować działania komunikacyjne i założenia wizerunkowe, utrzymując markę spójną i charakterystyczną na wielu, zróżnicowanych polach ekspozycji." nr="02." img={data.okladka.childImageSharp.fluid}>

        <TytulPunkty tekst="Stworzyliśmy kancelarią prawną, skupioną na sukcesie biznesowym klientów. 
        nowoczesną, dostępną i zaangażowaną." />  

        <Info invert={false} tytul="Proces tworzenia wizerunku" tekst="Trzyetapowy proces pozwolił nam opisać kryteria podziału rynku materiałów budowlanych i prefabrykacji, określić podział ról w procesie decyzyjnym, wyodrębnić grupy klientów i użytkowników produktów marki oraz przygotować fundamenty komunikacyjne, które odpowiadają na pragnienia i problemy indywidualnych grup odbiorców." />

  


        <SliderTaby 
        img1={data.slider_1.childImageSharp.fluid}
        img2={data.slider_2.childImageSharp.fluid}
        img3={data.slider_3.childImageSharp.fluid}
        tytul1="SEGMENTACJA"
        tytul1="TARGETOWANIE"
        tytul1="POZYCJONOWANIE"
        tekst1="Na poziomie etapu segmentacji określone zostały kryteria podziału kategorii produktowych i dopasowane do profili grup klientów a następnie określono priorytety dla działań sprzedażowych i w konsekwencji działań komunikacyjnych. Tym samym potwierdzono, w jakich segmentach marka operuje i dokonano wyróżnienia segmentów produktowych"
        tekst2="Pojęcie archetypu zostało spopularyzowane w pierwszej połowie XX w. przez Carla Gustava Junga, dla którego Inspirację stanowiły pisma starożytnych, m.in. św. Augustyna i Dionizego Pseudo-Areopagity. Szwajcarski "
        tekst3="Pojęcie archetypu zostało spopularyzowane w pierwszej połowie XX w. przez Carla Gustava Junga, dla którego Inspirację stanowiły pisma starożytnych, m.in. św. Augustyna i Dionizego Pseudo-Areopagity. Szwajcarski " />




      
     


        <div><Img fluid={data.vp.childImageSharp.fluid} /></div>

       
        
     

        

        
        
        </Rozdzial>
        
        </>
    )
}