import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from 'react'
import Komp_kol from '../comp/kol_tekst'
import Komp_kol2 from '../comp/komp_2kol_tekst_foto'
import Rozdzial from '../comp/rozdzial'
import TytulPunkty from '../comp/tytul_punkty'



export default function Identyfikacja() {
    const data = useStaticQuery(graphql`
            query {
               

                okladka: file(relativePath: { eq: "projekty/rite/3/okladka@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid(maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

               

                komp1: file(relativePath: { eq: "projekty/rite/3/komp1@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                komp2: file(relativePath: { eq: "projekty/rite/3/komp2@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                

                siatka: file(relativePath: { eq: "projekty/rite/3/siatka@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                final: file(relativePath: { eq: "projekty/rite/3/final@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                system: file(relativePath: { eq: "projekty/rite/3/system@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

            }
            `)

    


   
    
    
    return(
        <>

        <Rozdzial tytul="IDENTYFIKACJAMARKI" invert={false} tekst="Zależało nam na stworzeniu charakterystycznej marki nowoczesnej kancelarii prawnej, która, uwzględniając dynamikę rynku, utrzyma jej wymagania, wprowadzając nową jakość w branży. Wizerunek marki miał być elegancki i dynamiczny a kancelaria miała zwinnie odnajdować się w cyfrowej rzeczywistości." nr="03." img={data.okladka.childImageSharp.fluid}>
        
        <TytulPunkty tekst="Sukces to efekt przebytej drogi – 
to dążenie i jego efekt chcieliśmy oddać  w identyfikacji marki Rite. "
        tekst1="„I” w logotypie zostało wykorzystane jako symbol drogi i osiągniętego celu. To główne komunikaty marki, których wizualizacja była dla nas jednym z głównych założeń w SIW."
        tekst2="Zestawienie czerni, budującej powagę i poczucie elegancji z intensywnym odcieniem koloru pomarańczowego tworzy kontekst nowoczesności." />

        <div><Img fluid={data.siatka.childImageSharp.fluid}></Img></div>

        <Komp_kol tekst="System zbudowany został na kwadratowej siatce, wynikającej z symbolu stworzonego w w ramach identyfikacji marki - kropki nad „I” symbolizującej sukces i realizację celu. Dzięki takiemu podejściu mamy gwarancję spójności materiałów i kontroli nad ich efektem. Systemowe planowanie materiałów wizualnych jest także istotnym elementem optymalizacji czasu i kosztów prac graficznych w długiej perspektywie." tytul="SYSTEM" invert={true} img={data.system.childImageSharp.fluid} />

        <TytulPunkty tekst="Wyraźny kolor marki zestawiony z stonowanymi materiałami pozwala na wyodrębnienie kluczowych treści przy zachowaniu elegancji i jakości."
        />

        <Komp_kol2 
        tekst1="W ramach współpracy z kancelarią Rite stworzyliśmy pełen zestaw podstawowych druków akcydensowych - wizytówki, papiery firmowe, koperty, teczki biurowe, obwoluty na akta. Dzięki systemowym rozwiązaniom mamy gwarancję, że kolejne tworzone materiały będą zachowywały spójność i rozwijały charakter marki." 
        tytul1="Skala produkcji" 
        tekst2="Eleganckie i charakterystyczne druki marki Rite mają budować poczucie jakości kancelarii w oczach, określonych na etapie strategii marki, grup klientów. Systemowe tworzenie rozwiązań wizualnych umożliwiło również rozciągnięcie bazowych założeń na kwestie produkcyjne - dzięki temu wszystkie materiały tworzone są na jednolitych papierach i utrzymują jednakowe techniki uszlachetnień, budując poczucie spójności i charakteru marki." 
        tytul2="Druki akcydensowe" 
        invert={true} 
        img1={data.komp1.childImageSharp.fluid} 
        img2={data.komp2.childImageSharp.fluid}  />


        <div><Img fluid={data.final.childImageSharp.fluid}></Img></div>

        

    


       
        
       

        

        
        
        </Rozdzial>
        
        </>
    )
}