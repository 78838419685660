import { graphql, useStaticQuery } from "gatsby"
import React from 'react'
import Rozdzial from '../comp/rozdzial'
import Slider from '../comp/slider_info'
import TytulPunkty from '../comp/tytul_punkty'



const informacje = [
    {
        tytul: "DESK RESEARCH",
        opis: "Poznanie branży, analiza raportów branżowych, mediów branżowych, audyt obecności w mediach marki klienta i działań konkurencji."
    },
    {
        tytul: "ANALIZA DANYCH ZASTANYCH",
        opis: "Analiza danych dostarczonych przez klienta - poznanie produktów, wyników sprzedaży, efektywności działań mediowych, analiza modelu biznesowego. Przekrojowe poznanie dynamiki funkcjonowania biznesu."
    },
    {
        tytul: "POGŁĘBIONE WYWIADY WEWNĘTRZNE",
        opis: "Rozmowy bezpośrednie z ekspertami firmy Betard - członkami Zarządu, szefami, działów produktowych, BOK, HR i marketingu. Uzupełnienie wiedzy o modelu firmy, poszerzenie wiedzy o produktach, relacjach z klientami, o ich potrzebach i problemach."
    },
    {
        tytul: "POGŁĘBIONE WYWIADY ZEWNĘTRZNE",
        opis: "Rozmowy z przedstawicielami grup klientów firmy. Poznanie potrzeb, oczekiwanych korzyści, problemów, procesów, i postrzegania marki. Wywiady indywidualne, transkrypcja, kodowanie, analiza."
    },
    {
        tytul: "BADANIA UŻYTECZNOŚCI",
        opis: "Badania użyteczności strony internetowej marki. Analiza user flows, weryfikacja widoczności i dostępności elementów. Opracowanie rekomendacji dla wdrożenia nowej witryny www.rite.pl."
    },
]

export default function Research() {
    const data = useStaticQuery(graphql`
            query {
             

                okladka: file(relativePath: { eq:  "projekty/rite/1/okladka@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid(maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                    }


               

                
            }
            `)
    return(
        <>

        <Rozdzial tytul="RESEARCH I KONCEPCJA" tekst="Niezależnie od skali projektu, jedno jest pewne - dobry research przekłada się na efekt ostateczny. Każda branża rządzi się swoimi prawami a proponowane rozwiązania powinny uwzględniać jej dynamikę, rozumieć i wyprzedzać działania konkurencji oraz dopasowywać się do klientów, wobec których kierowana jest oferta marki. Bardzo konserwatywny i mocno regulowany rynek prawniczy stanowił dla nas wyzwanie ale jednocześnie mobilizował do poszukiwania nowych, lepszych rozwiązań." nr="01." img={data.okladka.childImageSharp.fluid}>

        <TytulPunkty tekst="Naszym celem było znalezienie niezagospodarowanej przestrzeni dla wizerunku kancelarii na bardzo mocno nasyconym rynku usług prawnych. Postawiliśmy na sukces i spełnienie oczekiwań w opozycji do powszechnej komunikacji bezpieczeństwa i promocji własnych kompetencji." tekst1="W ramach analizy, zapoznaliśmy się z pozycjonowaniem i działaniami komunikacyjnymi 50 kancelarii w Polsce, analizując podmioty wyróżnione w rankingu Rzeczpospolitej, pojawiające się na określone zapytania w wyszukiwarce Google oraz wskazane przez klienta jako benchmark."
        tekst2="Pomimo dużej ilości podmiotów na rynku, ich komunikacja jest bardzo jednolita, rzadko konkretna i oparta na rzeczywistych korzyściach dla klientów. Głównie, kancelarie prawne mówią o sobie, swoich kompetencjach, pomijając problemy i oczekiwania klientów." />

        <Slider tytul="Narzędzia badawcze" informacje={informacje} />


       



        </Rozdzial>
        
        </>
    )
}